import * as React from 'react';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';

function LaunchplanPage() {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  function getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'LaunchplanPage', fullWidth: true });
        return (
          <>
            <Seo title="kostenloser Shopify-Launchplan" />
            <div className="px-4 lg:px-16 pt-20 lg:pt-36">
              <div
                className="klaviyo-form-RYTZnv"
                data-klaviyo-accepted={
                        mounted ? getCookie('gatsby-gdpr-klaviyo') === 'true' : ''
                    }
                data-text="Bitte stimmen Sie erst den Cookies zu, um das kostenlosen Shopify-Launchplan-Formular zu laden."
              />
            </div>
            <div className="my-24 lg:my-60" />
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default LaunchplanPage;
